export const whyUsData = [{video:'https://youtu.be/9SRNjTrFmKo',title:'Motivation',img:require('../../Images/aboutUsImages/Motivation.png')},{video:'https://youtu.be/lhS9H7aZ6wA',title: 'Science Basis',img:require('../../Images/aboutUsImages/Science.png')},{video:'https://youtu.be/kZ89x_PD4Zg',title:'Technology Basis',img:require('../../Images/aboutUsImages/TechBasis.png')},{video:'https://youtu.be/zm6uPxlY24o',title:'Motivation Cycle',img:require('../../Images/aboutUsImages/MotivationCycle.png')},{video:'https://youtu.be/DGODpkawsgM',title:'Scientific Method, Diagram',img:require('../../Images/aboutUsImages/ScientificDiagram.png')},{video:'https://youtu.be/mdLkSQo_tqc',title:'Technological Examples',img:require('../../Images/aboutUsImages/TechnologyExamples.png')}]
export const ideaData = [{video:'https://youtu.be/dRzNZbgvoMU',title:'The Idea',img:require('../../Images/aboutUsImages/TheIdea.png')},{video:'https://youtu.be/Wz_CSFpElLI',title:'Starting Point',img:require('../../Images/aboutUsImages/StartingPoint.png')},{video:'https://youtu.be/cC6RwU_3qYs',title:'Why Beneficial',img:require('../../Images/aboutUsImages/WhyBeneficial.png')}]
export const techniqueAndTechnology = [{video:'https://youtu.be/Dc4xMc9UTn8',title:'Technics and Technology. Part 1',img:require('../../Images/aboutUsImages/TechnicsAndTechnology1.png')},{video:'https://youtu.be/V6BpPmc0i9Y',title:'Technics and Technology. Part 2',img:require('../../Images/aboutUsImages/TechnicsAndTechnology2.png')}]
export const classifications = [{video:'https://youtu.be/hZQGO46d9zE',title:'Classifications and Subjects. Part 1',img:require('../../Images/aboutUsImages/ClassificationsAndSubjects1.png')},{video:'https://youtu.be/38LXh5bg0-4',title:'Classifications and Subjects. Part 2',img:require('../../Images/aboutUsImages/ClassificationsAndSubjects2.png')}]
export const littleScientists = [{video:'https://youtu.be/D1I0ycRb9sc',title:'Little Scientists. Part 1',img:require('../../Images/aboutUsImages/LittleScientists1.png')},{video:'https://youtu.be/gtoX66Cecfw',title:'Little Scientists. Part 2',img:require('../../Images/aboutUsImages/LittleScientists2.png')}]
export const yourHelp = [{video:'https://youtu.be/d_-4ksumyV0',title:'How to help project',img:require('../../Images/aboutUsImages/HowToHelpProject.png')},{video:'https://youtu.be/dnwzTdA7ZTc',title:'Authors benefits',img:require('../../Images/aboutUsImages/AuthorsBenefits.png')}]
export const humanKnowledgeAndScience = [{video:'https://youtu.be/70r5F__RP2s',title:'Human Knowledge',img:require('../../Images/aboutUsImages/HumanKnowledge.png')},{video:'https://youtu.be/Uh70YCF6LNg',title:'Through Science Lenses. Part 1',img:require('../../Images/aboutUsImages/ThroughScienceLenses1.png')},{video:'https://youtu.be/9spg4NjEvIo',title:'Through Science Lenses. Part 2',img:require('../../Images/aboutUsImages/ThroughScienceLenses2.png')},{video:'https://youtu.be/3LjTU56DUIM',title:'Methods of Science. Part 1',img:require('../../Images/aboutUsImages/MethodsOfScience1.png')},{video:'https://youtu.be/-s5xGuTjV6E',title:'Methods of Science. Part 2',img:require('../../Images/aboutUsImages/MethodsOfScience2.png')}]
export const titles = {
  'whyus': '<p>Check the format, style, and brief content of our videos in these samples to spark your initial curiosity</p>',
  'idea': '<p>Figure out how idea of this website was born, and how it could be useful for you to watch videos from our website.</p>',
  'humanKnowledgeAndScience': '<p>You could not find such concentrated information about human knowledge and science anywhere else!</p><p>We spent whole month to put super concentrated information about science, its logic and methods, squeezed within a few videos for you. Save hundreds of hours of your own research and watch all these videos we made for you about science one after another. The last video is the key to connect all dots and to grasp overall scientific approach, but do not skip first videos in this section to remind all terms to yourself.</p>',
  'techniqueAndTechnology': '<p>Figure out what is the difference and scope of technology, engineering, and science. Learn how technics and technology helps humans and how it was evolved over centuries till nowadays.</p>',
  'classifications': '<p>Figure out how human knowledge could be structured, labeled. And how such labels could speed up your search of certain information.</p>',
  'littleScientists': '<p>Learn how to build better conditions for your kids from brain development perspective.</p>',
  'yourHelp': '<p>Check how you can help with project. And consider to join community of our authors. It is much appreciated. You definitely have all our love and support in return! </p>'
}
